<template>
    <div class="main-container">
      <h5 class="pageTitle">비행 장소 수정/삭제</h5>
      <div class="form-container">
       
        <div class="form-group">
          <label for="name">비행장소명</label>
          <input v-model="form.name" type="text" class="input-box" />
        </div>
  
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-update" @click="updateAirfield">수정</button>
          <button class="button-delete" @click="deleteAirfield">삭제</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";  
  export default {
    name: "AirfieldUpdateDelete",
    props: ["id"], // UUID를 props로 받음
    data() {
      return {
        form: {
          uuid: this.id, 
          name: this.$route.query.name || "",          
        },
        
      };
    },
    computed: {
    },
    mounted() {
     console.log("넘어온 uuid:"+this.id);
     this.form.uuid = this.id;
     console.log("넘어온 name:"+this.$route.query.name)
        if (!this.form.name) {
        this.fetchAirfield();  // ✅ name이 없으면 API 조회
        }
    },
    methods: {
    // ✅ 비행장소 데이터 조회
    async fetchAirfield() {
      if (!this.id) {
        console.error("UUID가 전달되지 않았습니다.");
        return;
      }

      try {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        const token = tokenData ? tokenData.access_token : "";

        const response = await axios.get(`/master/airfield/${this.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        this.form = response.data; // 서버 응답 데이터를 form에 할당
      } catch (error) {
        console.error("비행장소 조회 실패:", error);
      }
    },

    // ✅ 비행장소 수정
    async updateAirfield() {
      if (!this.form.name) {
        Swal.fire("오류", "비행장소명을 입력하세요.", "error");
        return;
      }

      try {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        const token = tokenData ? tokenData.access_token : "";

        await axios.put(`/master/airfield/${this.id}`, {
          airfield: this.form.name,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        Swal.fire("성공", "비행장소가 수정되었습니다.", "success");
        this.goToList();
      } catch (error) {
        console.error("수정 실패:", error);
        Swal.fire("오류", "수정 중 문제가 발생했습니다.", "error");
      }
    },

    // ✅ 비행장소 삭제
    async deleteAirfield() {
      try {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        const token = tokenData ? tokenData.access_token : "";

        await axios.delete(`/master/airfield/${this.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        Swal.fire("성공", "비행장소가 삭제되었습니다.", "success");
        this.goToList();
      } catch (error) {
        console.error("삭제 실패:", error);
        Swal.fire("오류", "삭제 중 문제가 발생했습니다.", "error");
      }
    },

    goToList() {
      this.$router.push("/master-airfieldRead");
    },
  },
};
  </script>
  
  <style scoped>
  /* 동일한 스타일 유지 */
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .input-box,
  .select-box {
    flex: 1;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    max-width: 300px;
  }
  
  .input-select {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button-container {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-update {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-delete {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  </style>
  